import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import reportWebVitals from './reportWebVitals';
import './index.css';

import IndexPage from './indexPage.js'

import Ar from './allLangs/ar/arabic.js';
import En from './allLangs/en/english.js';
import ES from './allLangs/es/spanish.js';
import De from './allLangs/de/german.js';
import Fr from './allLangs/fr/french.js';
import Hi from './allLangs/hi/indian.js';
import Id from './allLangs/id/indonasian.js';
import It from './allLangs/it/italy.js';
import Ja from './allLangs/ja/japan.js';
import Ko from './allLangs/ko/korean.js';
import Pt from './allLangs/pt/portuguese.js';
import Ru from './allLangs/ru/russian.js';
import Th from './allLangs/th/thai.js';
import Tr from './allLangs/tr/turkish.js';
import Zh from './allLangs/zh/chinese.js';

import AboutUs from './official-doc/about.js';
import Privacy from './official-doc/privacy.js';
import Terms from './official-doc/terms.js';
import Contact from './official-doc/contact.js';

import NotFound from './err/404.js';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <Router  >
      <Routes >   
	  {/*<Route index element={<En  />}   />	 */ }
		<Route path="En" element={<En  />}   />
		<Route path="ja" element={<Ja  />}   />
		<Route path="ar" element={<Ar  />}   />
		<Route path="de" element={<De  />}   />
		<Route path="es" element={<ES  />}   />
		<Route path="fr" element={<Fr  />}   />
		<Route path="hi" element={<Hi  />}   />
		<Route path="id" element={<Id  />}   />
		<Route path="it" element={<It  />}   />
		<Route path="ko" element={<Ko  />}   />
		<Route path="pt" element={<Pt  />}   />
		<Route path="ru" element={<Ru  />}   />
		<Route path="th" element={<Th  />}   />
		<Route path="tr" element={<Tr  />}   />
		<Route path="zh" element={<Zh  />}   />
		
		
		<Route path="about" element={<AboutUs  />}   />
		<Route path="privacy" element={<Privacy  />}   />
		<Route path="terms" element={<Terms  />}   />
		<Route path="contact" element={<Contact  />}   />
		
		<Route path="*"  element={<En />} />
      </Routes>
    </Router>
);


reportWebVitals();


