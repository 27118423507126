const languageTranslations = {
    "axtryendheu": {},
    "langTransData": {
        "header": {
            "title": "YouTube downloader",
            "discription": "Download unlimited YouTube videos, save YouTube video links & information, after downloading videos from YouTube. SSYT is the best YouTube Downloader for downloading YouTube tutorials and various important YouTube videos, because of it's rare save YouTube video links & information feature. SSYT, Is an advanced YouTube downloader, with high-speed download, supports various video formats and it very safe."
        },
        "search_result_comp": {
            "en": {
                "header_text": "Adavanced YouTube downloader",
                "header_text_sub": "Download YouTube Videos | save YouTube video links & info",
                "placeholder_text": "Paste your youTube video link here",
                "download_button_text": "Download",
                "result_header_text": "Download video as:",
                "savelink_button_text": "Save YouTube video link",
                "savelink_button_text_title": "Save YouTube video URL, title, channel, duration, date",
                "video_text": "Video",
                "show_more_text": "Show more",
                "show_less_text": "Show less",
                "author_text": "Channel ",
                "duration_text": "Duration",
                "input_box_title": "Paste your youTube video link here",
                "fetching_data": "Fetching YouTube Video....."
            }
        },
        "saved_youtube_links_section": {
            "en": {
                "input_placeholder_text": "Find saved YouTube video's by $       ",
                "input_placeholder_text_title": "Find saved YouTube video's by $",
                "select_a": "Title",
                "select_b": "Author",
                "select_c": "Duration",
                "select_d": "Date",
                "select_e": "Link",
                "select_f": "Description",
                "select_g": "Channel",
                "select_value_a": "s_title",
                "select_value_b": "s_author",
                "select_value_c": "duration",
                "select_value_d": "date",
                "select_value_e": "url",
                "input_search_alert_a": "Couldn't match any YouTube video",
                "input_search_alert_a_cont": "you saved",
                "input_search_alert_b": "found",
                "input_search_alert_b_cont": "YouTube videos",
                "input_search_alert_b_cont_single": "YouTube video",
                "input_search_alert_c": "0 YouTube video info saved",
                "input_search_alert_c_cont": "saved",
                "copy_links_button_text_top": "Copy",
                "copy_links_info_button_text_top": "Copy",
                "copy_links_info_button_text_top_cont": "& info",
                "unsaved_videomsg_a": "You have not saved any YouTube video link",
                "unsaved_videomsg_b": "Download YouTube videos, then save the YouTube video links manually with the 'save Youtube video link' button.",
                "unsaved_videomsg_c": "OR",
                "unsaved_videomsg_d": "Turn On 'auto save', to automatically save your Youtube Video links after every download.",
                "select_button_title": "Select YouTube video",
                "select_button_copy_title": "Copy YouTube link",
                "select_button_delete_title": "Delete YouTube link",
                "select_button_visit_title": "View video on Youtube",
                "select_button_download_title": "Download Youtube Video",
                "auto_save_button_text": "Auto save ",
                "auto_save_button_title_text": "Auto save links after downloading",
                "copy_youtube_links_button_text": "Copy youTube links",
                "copy_youtube_links_button_title_text": "Extract all saved YoTtube links",
                "copy_youtube_links_info_button_text": "Copy YouTube links & info",
                "copy_youtube_links_info_button_title_text": "Extract all saved YouTube links & info",
                "auto_save_button_text_switch_text_on": "ON",
                "auto_save_button_text_switch_text_off": "OFF",
                "author": "Author",
                "channel": "Channel",
                "duration": "Duration",
                "link": "link",
                "links": "links",
                "input_search_alert_b_cont_info_text": "info",
                "delete_saved_yt_links_file": "Delete Youtube video links",
                "delete_saved_yt_links_file_msg": "Do you want to delete saved Youtube video links",
                "delete_saved_yt_links_yes": "Yes",
                "delete_saved_yt_links_no": "No",
                "delete_saved_yt_links_statusMsg": "Done!",
                "no_loc": "You have not saved any Youtube video link",
				"saved_YT_links_header":"Saved YouTube Links"
            }
        },
        "back_up_manager": {
            "en": {
                "back_up_button_text": "Backup Links",
                "retrive_button_text": "Retrive Links",
                "retrive_button_title": "Retrive backedup links",
                "back_up_button_text_mb": "Backup",
                "retrive_button_text_mb": "Retrive Links",
                "back_up_placeholder_text": "Backup links with a unique string",
                "retrive_placeholder_text": "Type your unique string",
                "back_up_input_title": "The unique string will be used to identify your saved data",
                "retrive_input_title": "Unique string you used for backup",
                "back_up_status": "Successfully backed up",
                "retrive_status": "Successfully retrived Youtube links",
                "back_up_err": "use another file name",
                "back_up_err_no_loc": "You have not saved Youtube links",
                "back_up_retive_err": "Unable to complete your request",
                "more_text": "Backup YouTube Links",
                "less_text": "Hide backup YouTube Links",
                "more_tools_avail": "Back up Youtube links & Batch download tools are avialable",
                "batch_download_header_text": "Batch download Youtube videos",
                "batch_download_textarea_placeholder_text": "Maximum of $ Youtube links",
                "batch_download_button_text": "Batch Download All",
                "batch_download_button_text_720": "Download All - 720p",
                "batch_download_success": "Succcesfully downloaded all",
                "batch_download_error": "Batch download failed",
                "batch_download_error_too_many_urls": "Youtube urls can't exceed $",
                "invalid_yt_url": "Detected invalid Youtube url",
                "Maximum_batch_download": 20,
                "batch_download_textarea_title": "Download many Youtube videos at once",
                "download_terminated": "Batch Download has been terminated",
                "download_terminated_title": "Terminate batch download",
                "add_youtube_links_err": "Add Youtube video links"
            }
        },
        "product_text": {
            "en": {
                "about_product_a": "<p>\n\t\t\t\tDownload YouTube Vidoes, Save YouTube Links and Info. SSYT YouTube Downloader is perfect for downloading important YouTube videos, because of its rare save YouTube video links and information feature. YouTube video downloaders, including students and various e-learners, finally have the rare opportunity to save YouTube video links and information after downloading YouTube videos.\n                </p>\n\t\t\t\t<p>\n                SSYT is the best and most reliable YouTube downloader online. How many YouTube downloaders can save YouTube video links after downloading YouTube videos?\n                </p>\n\t\t\t\t<p>\n                Download YouTube videos and save their links, so you don't lose the links to important YouTube videos you have downloaded. SSYT is a free online YouTube downloader and will remain free forever.\n                </p>\n\t\t\t\t<p>\n                Apart from saving links and information about YouTube videos downloaded, saved YouTube video links and information can also be found via their titles, author, description, date, and URL. Giving users lots of options to easily find downloaded YouTube videos when they forget video titles, get confused, or have lots of downloaded videos in their system.\n\t\t\t\t</p>\n\t\t\t\t<p></p>",
                "about_product_b": "<span translate='no'>{bizData.company_name}</span> does not intend to compete with world famous Free Youtube video downloader (ssyoutube.com), but compliment it's greatness with features users desperatly need, but currently unavailable on ssyoutube.com . <span translate='no'>{bizData.company_name}</span>, provides certian features like saving and managing downloaded Youtube video links and information, not currently avialable in ssyoutube.com. <span translate='no'>{bizData.company_name}</span> is a good alternative for ssyoutube.com.",
                "supported_platforms_header_text": "Supported Platforms",
                "supported_platforms_paragraph": "supports all platforms and devices.",
                "download_yt_video_header_text": "How To Download Youtube Videos",
                "download_yt_video_paragraph": "Paste the Youtube link in the Youtube link input field above, then select your preferred video format via the various download buttons.",
                "save_yt_video_header_text": "How To Save YouTube Video Links After Downloading",
                "save_yt_video_text_a": "1. Click on the \"Save Youtube video link\" button after downloading the video.",
                "save_yt_video_text_or": "OR",
                "save_yt_video_text_b": "2. Click on the auto-save button to automatically save Youtube video links after every download.",
                "save_yt_video_text_paragraph": "Have you ever wasted your precious time searching for Youtube videos you previously downloaded in your system or were unable to find links to previously downloaded Youtube videos? Save downloaded Youtube video links and information with <span translate='no'>{bizData.company_name}</span>.",
                "were_yt_links_saved_header_text": "Where Are Youtube Links Stored",
                "were_yt_links_saved_paragraph": "Downloaded Youtube video links and information are safely stored in your browser's local storage and optionally backed up on the <span translate='no'>{bizData.company_name}</span> data base.",
                "how_safe_header_text": "Is It Safe To Save Youtube Video Links On My Browser",
                "how_safe_paragraph": "Most web browsers have a local storage data base, for saving data in the browser. Data saved in the browsers local storage are stringified before saving for security reasons. It's 100% safe to save Downloaded Youtube video links and info with <span translate='no'>{bizData.company_name}</span>. Kindly verify our claims with various website security tools online.",
                "how_to_backup_header_text": "How To Back Up Youtube Links & Info",
                "how_to_backup_paragraph": "Saved Youtube links and information are backed up with a one-time unique string for identification and retrieval on the <span translate='no'>{bizData.company_name}</span> data base. Type in your unique string in the input field, then click the \"Backup Links\" button.",
                "how_to_batch_download_header": "How To batch Download Youtube Videos",
                "how_to_batch_download_text": "Paste all the Youtube video urls in the batch download text area, then click the 'Batch download all button'. All Youtube url links should be placed on separate lines and should not be separated by a \"comma (',')\".",
                "services_free_ytd_header": "Free YouTube Downloader",
                "services_free_ytd_header_p": "Free YouTube Downloader",
                "services_save_ytd_header": "Save YouTube Links",
                "services_save_ytd_header_p": "Save and manage downloaded YouTube video links.",
                "services_batch_ytd_header": "Batch Download",
                "services_batch_ytd_header_p": "Download many YouTube videos at once.",
                "privacy_header": "Will You Respect My Privacy",
                "privacy_text": "We respect your privacy and will never take advantage of you. We will never collect, store, or sell your personal information to a third party. We don't install cookies, trackers, bots and there likes when you visit or download YouTube videos with <span translate='no'>{bizData.company_name}</span>.",
                "product_story": "\n<p>\nWe developed SSYT in response to the growing need to save the links and information of each YouTube tutorial downloaded from YouTube, particularly among students and various e-learners relying on YouTube for educational purposes. SSYT is the result of our hard work and efforts to provide the YouTube video downloading community with a new downloader that addresses their current video downloading problems.\n</p>\n<b>\nPros of Saving YouTube Video Links and Info After Downloading YouTube Videos:\n</b>\n<ul>\n<li>\n<p>\nStudents and various e-learners can easily share the URL links to important YouTube tutorials they have used for educational purposes in the past or present with co-students and friends when the need arises.\n</p>\n</li>\n<li>\n<p>\nYouTube video downloaders can easily re-download YouTube videos if they delete the videos or format their systems, without wasting time searching for various YouTube videos they have downloaded in the past.\n</p>\n</li>\n<li>\n<p>\nThe SSYT save YouTube links tool makes it very easy for YouTube video downloaders to find, manage, organize, and navigate through many YouTube videos saved on their systems.\n</p>\n</li>\n</ul>\n"
            }
        },
        "save_link_status_msg": {
            "en": {
                "success": "YouTube video info was successfully saved",
                "error": "Error! This YouTube link has been saved before",
                "default_msg": ""
            }
        },
        "footer": {
            "en": {
                "services_a": "Download YouTube videos   ",
                "services_b": "   Save YouTube video links & info   ",
                "services_c": "Download many Youtube Videos at once",
                "services_by_text": "YouTube Video Downloader by",
                "menu_about": "About us",
                "menu_privacy": "Privacy Policy",
                "menu_terms": "Terms of Service",
                "menu_contact": "Contact"
            }
        }
    }
}
export default languageTranslations





