import { useState,useEffect,useLayoutEffect,useRef,fowardRef,useCallback,useFlushsync,Suspense,memo } from 'react';
import { TiCancelOutline } from "react-icons/ti";
import { GoStop } from "react-icons/go";



export default function Getxxa() {
	
	const [screenSize,setScreenSize] = useState(window.innerWidth)
    const doc = useRef();
    const doc2 = useRef();
	


    useEffect(() => {
		
	const displayAD = ()=>{
        if (doc.current && !doc.current.firstChild) {
			if(screenSize > 600){

		const atOptions = {
		'key' : '9306c7b1708cf1535db1c15658ba5d4e',
		'format' : 'iframe',
		'height' : 90,
		'width' : 728,
		'params' : {}
	    }

		
		
	
            const conf = document.createElement('script');
            const script = document.createElement('script');
            script.type = 'text/javascript';
			script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

            doc.current.append(conf);
            doc.current.append(script);
			}
			else{
			 const atOptions= {
	     	'key' : 'f69a62648cb3ba42abcb2764cbdd22a8',
	    	'format' : 'iframe',
	    	'height' : 50,
	    	'width' : 320,
	    	'params' : {}
	}
            const conf = document.createElement('script');
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `//www.topcreativeformat.com/${atOptions.key}/invoke.js`;
            conf.innerHTML = `atOptions= ${JSON.stringify(atOptions)}`;

            doc2.current.append(conf);
            doc2.current.append(script);
    	
			}
        }
	}
	
	setTimeout(()=>{
	displayAD()
	},3000)
	
    }, [doc,screenSize]);

    return (
	<>

	<div style={{width:"80%",marginTop:"1px",marginBottom:"20px",border:"0px solid gray",display:"flex",alignItems:"center",justifyContent:"center",textColor:"white",textAlign:"center"}} ref={doc}></div>
	<div style={{width:"80%",marginTop:"1px",marginBottom:"20px",border:"0px solid gray",display:"flex",alignItems:"center",justifyContent:"center",textColor:"white",textAlign:"center"}} ref={doc2}></div>
  </>
  )
}



