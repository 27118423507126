const languageTranslations = {
    "axtryendheu": {},
    "langTransData": {
        "header": {
            "title": "Baixador do YouTube",
            "discription": "O melhor downloader gratuito do YouTube disponível! Baixe vídeos ilimitados do YouTube, salve links e informações de vídeos do YouTube depois de baixar vídeos do YouTube. SSYT é o melhor downloader do YouTube para baixar tutoriais do YouTube e vários vídeos importantes do YouTube, por causa de seu raro recurso de salvar links de vídeos e informações do YouTube. SSYT, é um downloader avançado do YouTube, com download em alta velocidade, suporta vários formatos de vídeo e é muito seguro."
        },
        "search_result_comp": {
            "pt": {
                "header_text": "O melhor downloader gratuito do YouTube",
                "header_text_sub": "Baixe vídeos do YouTube | salvar links e informações de vídeos do YouTube",
                "placeholder_text": "Cole o link do seu vídeo do YouTube aqui",
                "download_button_text": "Download",
                "result_header_text": "Baixe o vídeo como:",
                "savelink_button_text": "Salvar link do vídeo do YouTube",
                "savelink_button_text_title": "Salve o URL do vídeo do YouTube, título, canal, duração, data",
                "video_text": "Vídeo",
                "show_more_text": "Mostre mais",
                "show_less_text": "Show less",
                "author_text": "Canal ",
                "duration_text": "Duração",
                "input_box_title": "Cole o link do seu vídeo do YouTube aqui",
                "fetching_data": "Buscando vídeo do YouTube....."
            }
        },
        "saved_youtube_links_section": {
            "pt": {
                "input_placeholder_text": "Encontre vídeos salvos do YouTube por $        ",
                "input_placeholder_text_title": "Encontre vídeos salvos do YouTube por $",
                "select_a": "Título",
                "select_b": "Autor",
                "select_c": "Duração",
                "select_d": "Data",
                "select_e": "Link",
                "select_f": "Descrição",
                "select_g": "Canal",
                "select_value_a": "s_title",
                "select_value_b": "s_author",
                "select_value_c": "duration",
                "select_value_d": "date",
                "select_value_e": "url",
                "input_search_alert_a": "Não foi possível corresponder a nenhum vídeo do YouTube",
                "input_search_alert_a_cont": "você salvou",
                "input_search_alert_b": "encontrado",
                "input_search_alert_b_cont": "Vídeos do YouTube",
                "input_search_alert_b_cont_single": "Vídeo do youtube",
                "input_search_alert_c": "0 informações de vídeo do YouTube salvas",
                "input_search_alert_c_cont": "salvou",
                "copy_links_button_text_top": "cópia de",
                "copy_links_info_button_text_top": "cópia de",
                "copy_links_info_button_text_top_cont": "& informações",
                "unsaved_videomsg_a": "Você não salvou nenhum link de vídeo do YouTube",
                "unsaved_videomsg_b": "Baixe vídeos do YouTube e salve os links de vídeo do YouTube manualmente com o botão 'salvar link de vídeo do YouTube'.",
                "unsaved_videomsg_c": "OU",
                "unsaved_videomsg_d": "Ative o 'salvamento automático' para salvar automaticamente os links dos vídeos do Youtube após cada download.",
                "select_button_title": "Selecione o vídeo do YouTube",
                "select_button_copy_title": "Copiar link do YouTube",
                "select_button_delete_title": "Excluir link do YouTube",
                "select_button_visit_title": "Ver vídeo no Youtube",
                "select_button_download_title": "Baixar vídeo do Youtube",
                "auto_save_button_text": "Salvamento automático ",
                "auto_save_button_title_text": "Salvar links automaticamente após o download",
                "copy_youtube_links_button_text": "Copiar links do YouTube",
                "copy_youtube_links_button_title_text": "Extraia todos os links salvos do YoTtube",
                "copy_youtube_links_info_button_text": "Copie links e informações do YouTube",
                "copy_youtube_links_info_button_title_text": "Extraia todos os links e informações salvos do YouTube",
                "auto_save_button_text_switch_text_on": "SOBRE",
                "auto_save_button_text_switch_text_off": "DESLIGADO",
                "author": "Autor",
                "channel": "Canal",
                "duration": "Duração",
                "link": "link",
                "links": "links",
                "input_search_alert_b_cont_info_text": "informações",
                "delete_saved_yt_links_file": "Excluir links de vídeo do Youtube",
                "delete_saved_yt_links_file_msg": "Você deseja excluir links de vídeos salvos do YouTube",
                "delete_saved_yt_links_yes": "Sim",
                "delete_saved_yt_links_no": "Não",
                "delete_saved_yt_links_statusMsg": "Feito!",
                "no_loc": "Você não salvou nenhum link de vídeo do Youtube",
				"saved_YT_links_header":"Links salvos do YouTube"
            }
        },
        "back_up_manager": {
            "pt": {
                "back_up_button_text": "Links de backup",
                "retrive_button_text": "Recuperar links",
                "retrive_button_title": "Recuperar links de backup",
                "back_up_button_text_mb": "Cópia de segurança",
                "retrive_button_text_mb": "Recuperar links",
                "back_up_placeholder_text": "Links de backup com uma string exclusiva",
                "retrive_placeholder_text": "Digite sua string exclusiva",
                "back_up_input_title": "A string exclusiva será usada para identificar seus dados salvos",
                "retrive_input_title": "String exclusiva que você usou para backup",
                "back_up_status": "Backup realizado com sucesso",
                "retrive_status": "Links do YouTube recuperados com sucesso",
                "back_up_err": "use outro nome de arquivo",
                "back_up_err_no_loc": "Você não salvou links do Youtube",
                "back_up_retive_err": "Não foi possível concluir sua solicitação",
                "more_text": "Faça backup de links do YouTube",
                "less_text": "Ocultar links de backup do YouTube",
                "more_tools_avail": "Links de backup do YouTube e ferramentas de download em lote estão disponíveis",
                "batch_download_header_text": "Download em lote de vídeos do YouTube",
                "batch_download_textarea_placeholder_text": "Máximo de $ links do Youtube",
                "batch_download_button_text": "Baixar tudo em lote",
                "batch_download_button_text_720": "Baixar tudo - 720p",
                "batch_download_success": "Baixado tudo com sucesso",
                "batch_download_error": "Falha no download em lote",
                "batch_download_error_too_many_urls": "Os URLs do YouTube não podem exceder $",
                "invalid_yt_url": "URL inválido do YouTube detectado",
                "Maximum_batch_download": 20,
                "batch_download_textarea_title": "Baixe muitos vídeos do Youtube de uma só vez",
                "download_terminated": "O download em lote foi encerrado",
                "download_terminated_title": "Encerrar download em lote",
                "add_youtube_links_err": "Adicione links de vídeo do Youtube"
            }
        },
        "product_text": {
            "pt": {
                "about_product_a": "<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n\t\t\t\tSSYT é muito perfeito para baixar tutoriais do YouTube e vários vídeos importantes do YouTube, devido aos raros links de vídeos salvos do YouTube e ao recurso de informações. </font><font style=\"vertical-align: inherit;\">Os baixadores de vídeos do YouTube, incluindo estudantes e vários alunos on-line, finalmente têm a rara oportunidade de salvar links e informações de vídeos do YouTube após baixar os vídeos do YouTube.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                SSYT é o melhor e mais confiável downloader online do YouTube. </font><font style=\"vertical-align: inherit;\">Quantos downloaders do YouTube podem salvar links de vídeos do YouTube depois de baixar vídeos do YouTube?\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Baixe vídeos do YouTube e salve seus links, para não perder os links de vídeos importantes do YouTube que você baixou. </font><font style=\"vertical-align: inherit;\">SSYT é um downloader online gratuito do YouTube e permanecerá gratuito para sempre.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Além de salvar links e informações sobre os vídeos baixados do YouTube, links e informações de vídeos salvos do YouTube também podem ser encontrados por meio de títulos, autor, descrição, data e URL. </font><font style=\"vertical-align: inherit;\">Oferece aos usuários muitas opções para encontrar facilmente vídeos baixados do YouTube quando eles esquecem os títulos dos vídeos, ficam confusos ou têm muitos vídeos baixados em seu sistema.\n\t\t\t\t</font></font></p>\n\t\t\t\t<p></p>",
                "about_product_b": "<span translate='no'>{bizData.company_name}</span>não pretende competir com o mundialmente famoso downloader gratuito de vídeos do YouTube (ssyoutube.com), mas complemente sua grandeza com recursos que os usuários precisam desesperadamente, mas atualmente indisponíveis emssyoutube.com. <span translate='no'>{bizData.company_name}</span>, fornece certos recursos, como salvar e gerenciar links e informações de vídeos baixados do YouTube, atualmente não disponíveis emssyoutube.com.<span translate='no'>{bizData.company_name}</span>é uma boa alternativa parassyoutube.com.",
                "supported_platforms_header_text": "Plataformas suportadas",
                "supported_platforms_paragraph": "suporta todas as plataformas e dispositivos.",
                "download_yt_video_header_text": "Como baixar vídeos do Youtube",
                "download_yt_video_paragraph": "Cole o link do YouTube no campo de entrada do link do YouTube acima e selecione o formato de vídeo de sua preferência por meio dos vários botões de download.",
                "save_yt_video_header_text": "Como salvar links de vídeo do YouTube após o download",
                "save_yt_video_text_a": "1. Clique no botão \"Salvar link do vídeo do YouTube\" após baixar o vídeo.",
                "save_yt_video_text_or": "OU",
                "save_yt_video_text_b": "2. Clique no botão de salvamento automático para salvar automaticamente os links de vídeos do Youtube após cada download.",
                "save_yt_video_text_paragraph": "Você já perdeu seu precioso tempo procurando vídeos do YouTube baixados anteriormente em seu sistema ou não conseguiu encontrar links para vídeos do YouTube baixados anteriormente? Salve links e informações de vídeos baixados do YouTube com<span translate='no'>{bizData.company_name}</span>.",
                "were_yt_links_saved_header_text": "Onde os links do YouTube são armazenados",
                "were_yt_links_saved_paragraph": "Os links e informações de vídeos baixados do YouTube são armazenados com segurança no armazenamento local do seu navegador e opcionalmente armazenados em backup no<span translate='no'>{bizData.company_name}</span>base de dados.",
                "how_safe_header_text": "É seguro salvar links de vídeos do Youtube no meu navegador",
                "how_safe_paragraph": "A maioria dos navegadores web possui uma base de dados de armazenamento local, para salvar dados no navegador. Os dados salvos no armazenamento local do navegador são codificados antes de serem salvos por motivos de segurança. É 100% seguro salvar links e informações de vídeos baixados do YouTube com<span translate='no'>{bizData.company_name}</span>. Verifique nossas reivindicações com várias ferramentas de segurança de sites online.",
                "how_to_backup_header_text": "Como fazer backup de links e informações do YouTube",
                "how_to_backup_paragraph": "Os links e informações salvos do YouTube são copiados com uma sequência única e única para identificação e recuperação no<span translate='no'>{bizData.company_name}</span>base de dados. Digite sua string exclusiva no campo de entrada e clique no botão “Links de backup”.",
                "how_to_batch_download_header": "Como fazer download em lote de vídeos do Youtube",
                "how_to_batch_download_text": "Cole todos os URLs de vídeos do YouTube na área de texto de download em lote e clique no botão ‘Batch download all button’. Todos os links de URL do Youtube devem ser colocados em linhas separadas e não devem ser separados por \"vírgula (',')\".",
                "services_free_ytd_header": "Downloader gratuito do YouTube",
                "services_free_ytd_header_p": "Downloader gratuito do YouTube",
                "services_save_ytd_header": "Salvar links do YouTube",
                "services_save_ytd_header_p": "Salve e gerencie links de vídeos baixados do YouTube.",
                "services_batch_ytd_header": "Download em lote",
                "services_batch_ytd_header_p": "Baixe muitos vídeos do YouTube de uma só vez.",
                "privacy_header": "Você respeitará minha privacidade",
                "privacy_text": "Respeitamos sua privacidade e nunca tiraremos vantagem de você. Nunca coletaremos, armazenaremos ou venderemos suas informações pessoais a terceiros. Não instalamos cookies, rastreadores, bots e similares quando você visita ou baixa vídeos do YouTube com <span translate='no'>{bizData.company_name}</span>.",
                "product_story": "\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nDesenvolvemos o SSYT em resposta à crescente necessidade de salvar os links e informações de cada tutorial do YouTube baixado do YouTube, especialmente entre estudantes e vários e-alunos que dependem do YouTube para fins educacionais. </font><font style=\"vertical-align: inherit;\">SSYT é o resultado de nosso trabalho árduo e esforços para fornecer à comunidade de download de vídeos do YouTube um novo downloader que resolva seus problemas atuais de download de vídeos.\n</font></font></p>\n<b><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nPrós de salvar links e informações de vídeos do YouTube após baixar vídeos do YouTube:\n</font></font></b>\n<ul>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nAlunos e vários e-alunos podem compartilhar facilmente os links de URL de tutoriais importantes do YouTube que usaram para fins educacionais no passado ou apresentar com colegas e amigos quando necessário.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nOs baixadores de vídeos do YouTube podem facilmente baixar novamente os vídeos do YouTube se excluírem os vídeos ou formatarem seus sistemas, sem perder tempo procurando vários vídeos do YouTube que baixaram no passado.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nA ferramenta SSYT para salvar links do YouTube torna muito fácil para os baixadores de vídeos do YouTube encontrar, gerenciar, organizar e navegar por muitos vídeos do YouTube salvos em seus sistemas.\n</font></font></p>\n</li>\n</ul>\n"
            }
        },
        "save_link_status_msg": {
            "pt": {
                "success": "As informações do vídeo do YouTube foram salvas com sucesso",
                "error": "Erro! Este link do YouTube foi salvo antes",
                "default_msg": ""
            }
        },
        "footer": {
            "pt": {
                "services_a": "Baixe vídeos do YouTube",
                "services_b": "Salve links e informações de vídeos do YouTube",
                "services_c": "Baixe muitos vídeos do Youtube de uma só vez",
                "services_by_text": "Baixador de vídeos do YouTube por",
                "menu_about": "Sobre nós",
                "menu_privacy": "política de Privacidade",
                "menu_terms": "Termos de serviço",
                "menu_contact": "Contato"
            }
        }
    }
}


export default languageTranslations