const languageTranslations = {
    "axtryendheu": {},
    "langTransData": {
        "header": {
            "title": "Youtube Downloader",
            "discription": "Il miglior downloader YouTube gratuito disponibile! Scarica video YouTube illimitati, salva collegamenti e informazioni video di YouTube, dopo aver scaricato video da YouTube. SSYT è il miglior YouTube Downloader per scaricare tutorial di YouTube e vari importanti video di YouTube, grazie alla sua rara funzione di salvataggio di collegamenti video e informazioni di YouTube. SSYT, è un downloader avanzato di YouTube, con download ad alta velocità, supporta vari formati video ed è molto sicuro."
        },
        "search_result_comp": {
            "it": {
                "header_text": "Il miglior downloader gratuito di YouTube",
                "header_text_sub": "Scarica video di YouTube | salva collegamenti e informazioni sui video di YouTube",
                "placeholder_text": "Incolla qui il link del tuo video di YouTube",
                "download_button_text": "Scaricamento",
                "result_header_text": "Scarica il video come:",
                "savelink_button_text": "Salva il collegamento al video di YouTube",
                "savelink_button_text_title": "Salva URL, titolo, canale, durata, data del video YouTube",
                "video_text": "video",
                "show_more_text": "Mostra di più",
                "show_less_text": "Show less",
                "author_text": "Canale ",
                "duration_text": "Durata",
                "input_box_title": "Incolla qui il link del tuo video di YouTube",
                "fetching_data": "Recupero video di YouTube....."
            }
        },
        "saved_youtube_links_section": {
            "it": {
                "input_placeholder_text": "Trova i video YouTube salvati per $        ",
                "input_placeholder_text_title": "Trova i video YouTube salvati per $",
                "select_a": "Titolo",
                "select_b": "Autore",
                "select_c": "Durata",
                "select_d": "Data",
                "select_e": "Collegamento",
                "select_f": "Descrizione",
                "select_g": "Canale",
                "select_value_a": "s_title",
                "select_value_b": "s_author",
                "select_value_c": "duration",
                "select_value_d": "date",
                "select_value_e": "url",
                "input_search_alert_a": "Impossibile trovare alcuna corrispondenza con nessun video di YouTube",
                "input_search_alert_a_cont": "hai salvato",
                "input_search_alert_b": "trovato",
                "input_search_alert_b_cont": "Video di Youtube",
                "input_search_alert_b_cont_single": "Video Youtube",
                "input_search_alert_c": "0 Informazioni sul video di YouTube salvate",
                "input_search_alert_c_cont": "salvato",
                "copy_links_button_text_top": "copia",
                "copy_links_info_button_text_top": "copia",
                "copy_links_info_button_text_top_cont": "& informazioni",
                "unsaved_videomsg_a": "Non hai salvato alcun collegamento video di YouTube",
                "unsaved_videomsg_b": "Scarica i video di YouTube, quindi salva manualmente i collegamenti dei video di YouTube con il pulsante \"Salva collegamento video di YouTube\".",
                "unsaved_videomsg_c": "O",
                "unsaved_videomsg_d": "Attiva il \"salvataggio automatico\" per salvare automaticamente i collegamenti ai video di Youtube dopo ogni download.",
                "select_button_title": "Seleziona il video di YouTube",
                "select_button_copy_title": "Copia il collegamento di YouTube",
                "select_button_delete_title": "Elimina il collegamento a YouTube",
                "select_button_visit_title": "Guarda il video su Youtube",
                "select_button_download_title": "Scarica il video di Youtube",
                "auto_save_button_text": "Salvataggio automatico ",
                "auto_save_button_title_text": "Collegamenti di salvataggio automatico dopo il download",
                "copy_youtube_links_button_text": "Copia i link di YouTube",
                "copy_youtube_links_button_title_text": "Estrai tutti i collegamenti YoTtube salvati",
                "copy_youtube_links_info_button_text": "Copia link e informazioni di YouTube",
                "copy_youtube_links_info_button_title_text": "Estrai tutti i collegamenti e le informazioni di YouTube salvati",
                "auto_save_button_text_switch_text_on": "SU",
                "auto_save_button_text_switch_text_off": "SPENTO",
                "author": "Autore",
                "channel": "Canale",
                "duration": "Durata",
                "link": "collegamento",
                "links": "collegamenti",
                "input_search_alert_b_cont_info_text": "informazioni",
                "delete_saved_yt_links_file": "Elimina i collegamenti ai video di Youtube",
                "delete_saved_yt_links_file_msg": "Vuoi eliminare i collegamenti video di Youtube salvati",
                "delete_saved_yt_links_yes": "SÌ",
                "delete_saved_yt_links_no": "NO",
                "delete_saved_yt_links_statusMsg": "Fatto!",
                "no_loc": "Non hai salvato alcun collegamento video di Youtube",
				"saved_YT_links_header":"Collegamenti YouTube salvati"
            }
        },
        "back_up_manager": {
            "it": {
                "back_up_button_text": "Collegamenti di backup",
                "retrive_button_text": "Recupera collegamenti",
                "retrive_button_title": "Recupera i collegamenti di backup",
                "back_up_button_text_mb": "Backup",
                "retrive_button_text_mb": "Recupera collegamenti",
                "back_up_placeholder_text": "Collegamenti di backup con una stringa univoca",
                "retrive_placeholder_text": "Digita la tua stringa univoca",
                "back_up_input_title": "La stringa univoca verrà utilizzata per identificare i dati salvati",
                "retrive_input_title": "Stringa univoca utilizzata per il backup",
                "back_up_status": "Backup eseguito correttamente",
                "retrive_status": "Link Youtube recuperati con successo",
                "back_up_err": "utilizzare un altro nome di file",
                "back_up_err_no_loc": "Non hai salvato i link di Youtube",
                "back_up_retive_err": "Impossibile completare la tua richiesta",
                "more_text": "Effettua il backup dei collegamenti YouTube",
                "less_text": "Nascondi i collegamenti YouTube di backup",
                "more_tools_avail": "Sono disponibili collegamenti Youtube di backup e strumenti di download batch",
                "batch_download_header_text": "Scarica in batch i video di Youtube",
                "batch_download_textarea_placeholder_text": "Massimo di $ link Youtube",
                "batch_download_button_text": "Scarica tutto in batch",
                "batch_download_button_text_720": "Scarica tutto - 720p",
                "batch_download_success": "Scaricato tutto con successo",
                "batch_download_error": "Download batch non riuscito",
                "batch_download_error_too_many_urls": "Gli URL di Youtube non possono superare $",
                "invalid_yt_url": "Rilevato URL Youtube non valido",
                "Maximum_batch_download": 20,
                "batch_download_textarea_title": "Scarica molti video di Youtube contemporaneamente",
                "download_terminated": "Il download batch è stato terminato",
                "download_terminated_title": "Termina il download batch",
                "add_youtube_links_err": "Aggiungi collegamenti video di Youtube"
            }
        },
        "product_text": {
            "it": {
                "about_product_a": "<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n\t\t\t\tSSYT è perfetto per scaricare tutorial di YouTube e vari importanti video di YouTube, grazie ai suoi rari collegamenti video di YouTube e alla funzionalità di informazioni. </font><font style=\"vertical-align: inherit;\">Chi scarica video da YouTube, inclusi studenti e vari e-learner, ha finalmente la rara opportunità di salvare collegamenti e informazioni sui video di YouTube dopo aver scaricato i video di YouTube.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                SSYT è il downloader YouTube migliore e più affidabile online. </font><font style=\"vertical-align: inherit;\">Quanti downloader di YouTube possono salvare i collegamenti ai video di YouTube dopo aver scaricato i video di YouTube?\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Scarica i video di YouTube e salva i relativi collegamenti, in modo da non perdere i collegamenti agli importanti video di YouTube che hai scaricato. </font><font style=\"vertical-align: inherit;\">SSYT è un downloader YouTube online gratuito e rimarrà gratuito per sempre.\n                </font></font></p>\n\t\t\t\t<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\n                Oltre a salvare collegamenti e informazioni sui video di YouTube scaricati, è possibile trovare collegamenti e informazioni sui video di YouTube salvati anche tramite titoli, autore, descrizione, data e URL. </font><font style=\"vertical-align: inherit;\">Offre agli utenti molte opzioni per trovare facilmente i video YouTube scaricati quando dimenticano i titoli dei video, si confondono o hanno molti video scaricati nel sistema.\n\t\t\t\t</font></font></p>\n\t\t\t\t<p></p>",
                "about_product_b": "<span translate='no'>{bizData.company_name}</span>non intende competere con il famoso downloader di video Youtube gratuito (ssyoutube.com), ma complimentalo per la sua grandezza con funzionalità di cui gli utenti hanno disperatamente bisogno, ma che al momento non sono disponibilissyoutube.com. <span translate='no'>{bizData.company_name}</span>, fornisce funzionalità specifiche come il salvataggio e la gestione dei collegamenti e delle informazioni dei video Youtube scaricati, attualmente non disponibili inssyoutube.com.<span translate='no'>{bizData.company_name}</span>è una buona alternativa perssyoutube.com.",
                "supported_platforms_header_text": "Piattaforme supportate",
                "supported_platforms_paragraph": "supporta tutte le piattaforme e i dispositivi.",
                "download_yt_video_header_text": "Come scaricare video da Youtube",
                "download_yt_video_paragraph": "Incolla il collegamento Youtube nel campo di inserimento collegamento Youtube in alto, quindi seleziona il formato video preferito tramite i vari pulsanti di download.",
                "save_yt_video_header_text": "Come salvare i collegamenti video di YouTube dopo il download",
                "save_yt_video_text_a": "1. Fare clic sul pulsante \"Salva collegamento video Youtube\" dopo aver scaricato il video.",
                "save_yt_video_text_or": "O",
                "save_yt_video_text_b": "2. Fare clic sul pulsante di salvataggio automatico per salvare automaticamente i collegamenti video di Youtube dopo ogni download.",
                "save_yt_video_text_paragraph": "Hai mai sprecato il tuo tempo prezioso alla ricerca di video Youtube scaricati in precedenza nel tuo sistema o non sei riuscito a trovare collegamenti a video Youtube scaricati in precedenza? Salva i collegamenti e le informazioni dei video Youtube scaricati con<span translate='no'>{bizData.company_name}</span>.",
                "were_yt_links_saved_header_text": "Dove vengono archiviati i collegamenti Youtube",
                "were_yt_links_saved_paragraph": "I collegamenti e le informazioni dei video Youtube scaricati vengono archiviati in modo sicuro nella memoria locale del browser e, facoltativamente, sottoposti a backup<span translate='no'>{bizData.company_name}</span>Banca dati.",
                "how_safe_header_text": "È sicuro salvare i collegamenti video di Youtube sul mio browser",
                "how_safe_paragraph": "La maggior parte dei browser Web dispone di un database di archiviazione locale per il salvataggio dei dati nel browser. I dati salvati nella memoria locale del browser vengono stringati prima del salvataggio per motivi di sicurezza. È sicuro al 100% salvare i collegamenti e le informazioni dei video Youtube scaricati<span translate='no'>{bizData.company_name}</span>. Ti preghiamo di verificare le nostre affermazioni con vari strumenti di sicurezza del sito web online.",
                "how_to_backup_header_text": "Come eseguire il backup dei collegamenti e delle informazioni di Youtube",
                "how_to_backup_paragraph": "I collegamenti e le informazioni di Youtube salvati vengono sottoposti a backup con una stringa univoca una tantum per l'identificazione e il recupero sul file<span translate='no'>{bizData.company_name}</span>Banca dati. Digita la tua stringa univoca nel campo di input, quindi fai clic sul pulsante \"Link di backup\".",
                "how_to_batch_download_header": "Come scaricare in batch i video di Youtube",
                "how_to_batch_download_text": "Incolla tutti gli URL dei video di Youtube nell'area di testo del download in batch, quindi fai clic sul pulsante \"Scarica tutto in batch\". Tutti i collegamenti URL di Youtube devono essere posizionati su righe separate e non devono essere separati da una \"virgola (',')\".",
                "services_free_ytd_header": "Scaricatore gratuito di YouTube",
                "services_free_ytd_header_p": "Scaricatore gratuito di YouTube",
                "services_save_ytd_header": "Salva i collegamenti di YouTube",
                "services_save_ytd_header_p": "Salva e gestisci i collegamenti ai video YouTube scaricati.",
                "services_batch_ytd_header": "Download in batch",
                "services_batch_ytd_header_p": "Scarica molti video di YouTube contemporaneamente.",
                "privacy_header": "Rispetterai la mia privacy",
                "privacy_text": "Rispettiamo la tua privacy e non approfitteremo mai di te. Non raccoglieremo, conserveremo o venderemo mai le tue informazioni personali a terzi. Non installiamo cookie, tracker, bot e altri Mi piace quando visiti o scarichi video di YouTube con <span translate='no'>{bizData.company_name}</span>.",
                "product_story": "\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nAbbiamo sviluppato SSYT in risposta alla crescente necessità di salvare i collegamenti e le informazioni di ogni tutorial di YouTube scaricato da YouTube, in particolare tra gli studenti e i vari e-learner che si affidano a YouTube per scopi didattici. </font><font style=\"vertical-align: inherit;\">SSYT è il risultato del nostro duro lavoro e dei nostri sforzi per fornire alla comunità di download di video di YouTube un nuovo downloader che risolva i loro attuali problemi di download di video.\n</font></font></p>\n<b><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nPro del salvataggio di collegamenti e informazioni sui video di YouTube dopo aver scaricato i video di YouTube:\n</font></font></b>\n<ul>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nStudenti e vari e-learner possono facilmente condividere i collegamenti URL a importanti tutorial di YouTube che hanno utilizzato per scopi didattici in passato o presenti con co-studenti e amici quando se ne presenta la necessità.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nChi scarica video di YouTube può facilmente riscaricare i video di YouTube se elimina i video o formatta i propri sistemi, senza perdere tempo a cercare i vari video di YouTube che hanno scaricato in passato.\n</font></font></p>\n</li>\n<li>\n<p><font style=\"vertical-align: inherit;\"><font style=\"vertical-align: inherit;\">\nLo strumento SSYT per salvare i collegamenti di YouTube rende molto semplice per i downloader di video di YouTube trovare, gestire, organizzare e navigare attraverso molti video di YouTube salvati sui loro sistemi.\n</font></font></p>\n</li>\n</ul>\n"
            }
        },
        "save_link_status_msg": {
            "it": {
                "success": "Le informazioni sul video di YouTube sono state salvate correttamente",
                "error": "Errore! Questo collegamento YouTube è stato salvato in precedenza",
                "default_msg": ""
            }
        },
        "footer": {
            "it": {
                "services_a": "Scarica video di YouTube",
                "services_b": "Salva collegamenti e informazioni sui video di YouTube",
                "services_c": "Scarica molti video di Youtube contemporaneamente",
                "services_by_text": "Scaricatore di video da YouTube di",
                "menu_about": "Chi siamo",
                "menu_privacy": "politica sulla riservatezza",
                "menu_terms": "Termini di servizio",
                "menu_contact": "Contatto"
            }
        }
    }
}
export default languageTranslations