
import { useState,useEffect,useLayoutEffect,useRef,fowardRef,useCallback,useFlushsync,Suspense } from 'react';
import NativeLang from "../../data/languageTranslationDE.js"
import bizData from "../../data/data.js"
import {Helmet} from "react-helmet";

import AppDE from '../../App.js'


export default function German({compPadding}) {


const languageType = "de"
 return(
  <main  style={{maxWidth:'100%',overflowX:'Hidden'}} >
  <Helmet  htmlAttributes={{ lang: languageType }}>
  <title>{NativeLang.langTransData.header.title} - {bizData.company_url} </title>
  <meta name="description" content={NativeLang.langTransData.header.discription}  />
 
  <link rel="canonical" href="https://www.ssyt.tech/de" />
  <link rel="alternate" href="https://www.ssyt.tech/"    hreflang="x-default"  />
  <link rel="alternate" href="https://www.ssyt.tech/en/" hreflang="en" />
  <link rel="alternate" href="https://www.ssyt.tech/es/" hreflang="es" />
  <link rel="alternate" href="https://www.ssyt.tech/de/" hreflang="de" />
  <link rel="alternate" href="https://www.ssyt.tech/ar/" hreflang="ar" />
  <link rel="alternate" href="https://www.ssyt.tech/pt/" hreflang="pt" />
  <link rel="alternate" href="https://www.ssyt.tech/ru/" hreflang="ru" />
  <link rel="alternate" href="https://www.ssyt.tech/id/" hreflang="id" />
  <link rel="alternate" href="https://www.ssyt.tech/tr/" hreflang="tr" /> 
  <link rel="alternate" href="https://www.ssyt.tech/fr/" hreflang="fr" />
  <link rel="alternate" href="https://www.ssyt.tech/it/" hreflang="it" />
  <link rel="alternate" href="https://www.ssyt.tech/hi/" hreflang="hi" />
  <link rel="alternate" href="https://www.ssyt.tech/ja/" hreflang="ja" />
  <link rel="alternate" href="https://www.ssyt.tech/ko/" hreflang="ko" />
  <link rel="alternate" href="https://www.ssyt.tech/th/" hreflang="th" />
  <link rel="alternate" href="https://www.ssyt.tech/zh/" hreflang="zh" />
  
  
  <link rel="dns-prefetch" href="//ajax.googleapis.com" />
  <link rel="dns-prefetch" href="//ssl.google-analytics.com" />
  <link rel="dns-prefetch" href="//www.google-analytics.com" />
  <link rel="preconnect" href="https://www.i.ytimg.com" />
  <link rel="dns-prefetch" href="https://www.i.ytimg.com" />
  </Helmet>
  
  
  <AppDE  languageType={languageType} nativeLanguage={NativeLang} compPadding={compPadding}  /> 
  </main>
 )  
 }


