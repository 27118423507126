import { useState,useEffect,useLayoutEffect,useRef,useCallback,Suspense,memo,lazy } from 'react';
import { BrowserRouter as Router, Routes, Route,redirect,Navigate } from 'react-router-dom';
import styles from './inputNresult.module.css'
import {FaLanguage,} from 'react-icons/fa'
import {BiRightArrowAlt} from 'react-icons/bi'
import {FaFacebook, FaTwitter, FaTiktok, FaYoutube, FaCopyright, FaArrowUp, FaArrowRight, FaUserFriends,FaShoppingBasket,FaHamburger,FaDownload} from 'react-icons/fa'
import {LuConstruction} from 'react-icons/lu'
import BeatLoader from "react-spinners/BeatLoader";
import axios from 'axios'

import YtLinkResultComp from './youtubeLinkResult.js'
import YtSearchResultComp from './searchResultComp.js'
const SavedLinkComp = lazy(() => import('../savedLinkComp/manageSavedLinks.js'));



function PasteLink({language,langTrans,bizData}) {

const [languageType, setLanguageType] = useState('en')
const [searchResults, setSearchResults] = useState()
const [ytObjectState,setYtObjectState] = useState()
const [hoveredListIndex,setHoveredListIndex] = useState()
const [resultSwitch,setResultSwitch] = useState(false)
const [showYTresult, setShowYTresult] =  useState(false)
const [downloadFormats,setDownloadFormats] = useState([0,1,2,3,4,5,6,7,8,9,10])
const [renderFormatsNum,setRenderFormatsNum] = useState(3)
const [msgSavingStatus,setMsgSavingStatus] = useState(langTrans.langTransData.save_link_status_msg[language].default_msg)
const [savedLinks, setSavedLinks] =  useState()
const resultList = useRef([])
const inputTextComp = useRef(null)
const inputTextCompArabic = useRef(null)
const [fetchingDataStatus,setFetchingDataStatus] = useState("")
const [localStorageExist, setLocalStorageExist] =  useState(true)
const [isSelectedLink, setIsSelectedLink] =  useState([])
const [showLoadingSprite, setShowLoadingSprite] =  useState(false)

const [routes, setRoutes] =  useState()

const [test, setTest] =  useState()


const showInputText = async(e)=>{
	if(language !== "ar"){
	inputTextComp.current.value.length < 1 && setTimeout(()=>{setShowLoadingSprite(false)},100)
	inputTextComp.current.value.length < 1 && setResultSwitch(false)
	inputTextComp.current.value.length < 1 && setShowYTresult(false)
	inputTextComp.current.value = await e.target.value
	}
	else{
	inputTextCompArabic.current.value.length < 1 && setTimeout(()=>{setShowLoadingSprite(false)},100)
	inputTextComp.current.value.length < 1 && setResultSwitch(false)
	inputTextComp.current.value.length < 1 && setShowYTresult(false)
	inputTextCompArabic.current.value = await e.target.value
	}
}


const getVideoInfo = async()=>{
	if(typeof  routes === "object"){
	msgSavingStatus !== "" && setMsgSavingStatus(langTrans.langTransData.save_link_status_msg[language].default_msg)
	resultSwitch && setResultSwitch(false)
	if(language !== "ar"){
	inputTextComp.current.value !== "" && searchString(inputTextComp.current.value)
	}
	else{
	inputTextCompArabic.current.value !== "" && searchString(inputTextCompArabic.current.value)
	}
	}
	else{
	return false
	}
}

const searchString = (string)=>{
const youtubeRegex = /(http:|https:)?(\/\/)?(www\.)?(youtube.com|youtu.be)\/(watch|embed)?(\?v=|\/)?(\S+)?/
!showLoadingSprite && setShowLoadingSprite(true)
if(youtubeRegex.test(string)){
VideoMetaInfo(string)
}
else{
fetchWithString(string)
}
}

const VideoMetaInfo = (urlLink)=>{
resultSwitch && setResultSwitch(false)
typeof searchResults === "object" && setSearchResults()
axios.post(routes.info, {
  url: urlLink
  })
  .then(async(response)=> {
   setYtObjectState(response.data)
  })
  .then(()=>{
  setShowYTresult(true)
  setShowLoadingSprite(false)
  })
  .catch((error)=> {
   console.log(error);
  })		
}



const fetchWithString = (string)=>{
showYTresult && setShowYTresult(false)
typeof ytObjectState === "object" && setYtObjectState()
axios.post(routes.searchString, {
  string: string
  })
  .then((response)=> {
   setSearchResults(response.data)
  })
  .then(()=>{
  setResultSwitch(true)
  setShowLoadingSprite(false)
  })
  .catch((error)=> {
   console.log(error);
  })	
}


function getRoutes(){
axios.get("https://www.api.gateway1.ssyt.tech/route")
  .then((response)=> {
   setRoutes(response.data)
  })
  .catch((error)=> {
   console.log(error);
  })

}
  
useEffect(()=>{
getRoutes()
},[])
 

const langWithLongChar = ["ja","hi","es","fr","de","it","pt","ru","th","tr"]



  

 return(
 <div>
 <div  className={styles.inputNresultsCompSection}>
 <section  >
 <center>
 <div  className={langWithLongChar.indexOf(language) === -1 ? styles.inputNresultsComp : styles.inputNresultsCompLongChar}>

 <div className={styles.inputNbuttonContainer}>
 <center>
 
 <div className={styles.inputNbuttonFrame} >

 <div style={{display:language === "ar" ? "none" : "block"}}  className={langWithLongChar.indexOf(language) === -1 ? styles.inputFrame : styles.inputFrameLongChar }>
 <input ref={inputTextComp} required="required" name="search" className={styles.input} title={langTrans.langTransData.search_result_comp[language].input_box_title}
 type="text" placeholder={langTrans.langTransData.search_result_comp[language].placeholder_text} onChange={(e)=>showInputText(e)}   />
 </div>

 <div style={{display:language === "ar" ? "block" : "none"}}  className={styles.DownloadbuttonContainerArab}>
 <button id="download-youtube-video" aria-label={langTrans.langTransData.search_result_comp[language].download_button_text}
 className={styles.Downloadbutton} onClick={getVideoInfo}>
 <span className={styles.DownloadbuttonIcon}><BiRightArrowAlt /></span> <span className={styles.DownloadText}>{langTrans.langTransData.search_result_comp[language].download_button_text}</span> 
 </button>
 </div>
 

 <YtSearchResultComp language={language} langTrans={langTrans} bizData={bizData} searchResults={searchResults} setSearchResults={setSearchResults}
 resultSwitch={resultSwitch} setResultSwitch={setResultSwitch} inputTextComp={inputTextComp} inputTextCompArabic={inputTextCompArabic}
 showLoadingSprite={showLoadingSprite} setShowLoadingSprite={setShowLoadingSprite} routes={routes} setRoutes={setRoutes} 
 ytObjectState={ytObjectState} setYtObjectState={setYtObjectState} showYTresult={showYTresult} setShowYTresult={setShowYTresult} />


 <div style={{display:language === "ar" ? "block" : "none"}} className={styles.inputFrameArab}>
 <input name="search" ref={inputTextCompArabic} required="required" dir="rtl" className={styles.input} title={langTrans.langTransData.search_result_comp[language].input_box_title} type="text" placeholder={langTrans.langTransData.search_result_comp[language].placeholder_text} onChange={(e)=>showInputText(e)}  />
 </div>
 
 <div style={{display:language === "ar" ? "none" : "block"}}  className={langWithLongChar.indexOf(language) === -1 ? styles.DownloadbuttonContainer : styles.DownloadbuttonContainerLongChar }>
 <button  id="download-youtube-video-arabic" aria-label={langTrans.langTransData.search_result_comp[language].download_button_text}  
 className={styles.Downloadbutton} onClick={getVideoInfo}>
 <span className={styles.DownloadText}>{langTrans.langTransData.search_result_comp[language].download_button_text}</span> <span className={styles.DownloadbuttonIcon}><BiRightArrowAlt /></span>
 </button>
 </div>
 
 </div>
 </center>
 </div>
 </div>
 <div className={styles.sprite}  style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"1rem"}}>
	 {showLoadingSprite ? <img src="loader.svg" /> : ""}
 </div>
 </center>
 </section>
 

 <YtLinkResultComp language={language} langTrans={langTrans} bizData={bizData} ytObjectState={ytObjectState} setYtObjectState={setYtObjectState} 
 localStorageExist={localStorageExist} setLocalStorageExist={setLocalStorageExist} savedLinks={savedLinks} setSavedLinks={setSavedLinks} setIsSelectedLink={setIsSelectedLink} 
 downloadFormats={ downloadFormats} renderFormatsNum={renderFormatsNum} setRenderFormatsNum={setRenderFormatsNum} showYTresult={showYTresult}
 setShowYTresult={setShowYTresult} msgSavingStatus={msgSavingStatus} setMsgSavingStatus={setMsgSavingStatus} routes={routes} setRoutes={setRoutes}  />


 
 </div>
<Suspense>
 <SavedLinkComp savedLinksState={savedLinks} setSavedLinksState={setSavedLinks} localStorageExist={localStorageExist} setLocalStorageExist={setLocalStorageExist} 
 language={language} langTrans={langTrans} bizData={bizData} isSelectedLink={isSelectedLink} setIsSelectedLink={setIsSelectedLink} routes={routes} setRoutes={setRoutes}  /> 
</Suspense>
 </div>
 )  
 }


export default PasteLink