
const  portfolioData = {
  "company_name": "SSYT",
  "company_name_u": "SSYT",
  "company_url": "ssyt.tech",
  "company_full_url": "https://ssyt.tech/",
  "last_name": "",
  "website_theme_background_color":"rgb(0,0,0)",
  "social": [
    {
      "icon": "facebook",
      "url": "#",
      "id": 0
    },
    {
      "icon": "instagram",
      "url": "#",
      "id": 1
    },
    {
      "icon": "twitter",
      "url": "#",
      "id": 2
    },
    {
      "icon": "behance",
      "url": "#",
      "id": 3
    },
	 {
      "icon": "dribbble",
      "url": "#",
      "id": 4
    },
	{
      "icon": "linkedin",
      "url": "#",
      "id": 5
    }
  ],
  "website_square_icons": "FaSquare",
  "portfolio_pix": "owner-1.png",
  "portfolio_pix_width": 95,
  "portfolio_image_margin": {
  "margin_top": 6,
  "margin_bottom": 1
  },
   "footer_text": {
	"phone_text":"",
	"email_text":"",
	"email_text2":""
  },
   "footer_styles": {
	"text_color":"rgb(251,251,251)",
	"border_bottom_color":"white",
	"bg_color":"rgba(41, 11, 135,0.9)"
  },
  "header_assets":{
  "portfolio_onwer_name_color":"white",
  "nav_text_color":"rgb(81,82,84)",
  "nav_underline_color":"rgb(69,74,78)",
  "social_icons_color":"rgb(37,38,40)",
  "social_icons_seperator_color":"rgb(136, 139, 149)",  
  "social_icons_color_hover":"rgb(99, 98, 98)",
  "mobile_icons_open_close":"bg-gray-100",
  "hamburger_menu_background_color": "",
  "header_background_color":"rgba(41, 11, 135,0.9)"
  },
  "email_js":{
   "SERVICE_ID":"service_qdhmczd",
   "TEMPLATE_ID":"template_4w0yrpm",
   "PUBLIC_KEY":"cLQe6XQQUBNhZ8CDS"
  }

  
}
 export default portfolioData